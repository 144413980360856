import { plexiRequest } from '.'

export const getForecastMeasurements = () =>
  plexiRequest({ url: '/forecast/signals/map/' })
    .then(data => data.map(signal => ({
      text: signal.name,
      value: signal.code,
      units: signal.units
    })))
    .catch(err => ([{
      error: true,
      message: err
    }]))

export const getForecastPlotData = async ({
  measurement,
  aggregation,
  meters,
  timeStart,
  timeEnd,
  isSupervisor
}) => {
  const queryParams = `
        CODE=${measurement}& \
        aggregation=${aggregation}& \
        TIME_START=${timeStart}& \
        TIME_END=${timeEnd}& \
        is_supervisor=${isSupervisor} \
      `.replace(/  +/g, '')

  return measurement && meters && timeStart && timeEnd &&
    plexiRequest({
      url: `/forecast/predict?${queryParams}`,
      method: 'post',
      data: {
        METERS: meters
      }
    }).then(data => {
      return aggregation === 'NONE'
        ? {
            [aggregation]: meters.map(meter => ({
              ...data[aggregation][meter],
              meter
            }))
          }
        : { ...data, meters }
    })
      .catch(err => ({ error: true, message: err }))
}
